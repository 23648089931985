import React from "react"
import News from "../../../blocks/en/news_page"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export const pageQuery = graphql`
query {
  news: newsYaml(slug: { eq: "asani_ska" }) {
    ...NewsInfoEn
    innerImages {
      signing {
        childImageSharp {
          fluid(maxWidth: 1680) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
}`

export default class NewsTextEn extends News {
  renderNews() {
    const { signing } = this.props.data.news.innerImages
    return (
      <div className="row">
        <div className="col-xl-12">
          <p>23-year-old left-back Xhelil Asani has become a client of Catenaccio Sports Agency and, with the participation of the agency, signed a one-year contract with SKA Khabarovsk, becoming the first Northern Macedonian in the club’s history. Before SKA, Asani played for Mladost (Bosnia).</p>
          
          <Img fluid={ signing.childImageSharp.fluid } alt="Contract signing" width="100%" />

          <div className="video">
            <iframe
              src="https://www.youtube.com/embed/4plpJ1xecu0"
              frameBorder="0"
              allow="autoplay; encrypted-media"
              allowFullScreen
            />
          </div>
        </div>
      </div>
    )
  }
}